
// just an idea right now, not in use

import { reactive, type Ref, type Reactive } from "vue";
import BrowserQRCodeReader from "./temp.BrowserQRCodeReader.ts";

export default class Scanner {
    videoRef: Ref<HTMLVideoElement>;
    reader: BrowserQRCodeReader;

    get running(): boolean { return this.reader.running; }

    constructor(videoRef: Ref<HTMLElement>) {
        this.videoRef = videoRef;
        this.reader = new BrowserQRCodeReader(videoRef, this.onScan);
    }

    onScan(res: any, err: any): void {

    }

    async start(): Promise<void> {
        await this.reader.start();
    }

    async stop(): Promise<void> {
        await this.reader.stop();
    }
}

export function useScanner(...args: ConstructorParameters<typeof Scanner>): Reactive<Scanner> {
    return reactive(new Scanner(...args));
}
